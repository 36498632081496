export default class ContactSelection {
  constructor(element) {
    this.el = element;
    this.firstLevelBox = this.el.querySelector(".first-level-support-selected");
    this.firstLevelSelected = this.firstLevelBox.querySelector(".first-level-selected");
    this.secondLevelBox = this.el.querySelector(".second-level-support-selected");
    this.secondLevelSelected = this.el.querySelector(".second-level-selected");
    this.secondLevelSupport = this.el.querySelector(".second-level-support");
    this.firstLevelContainer = this.el.querySelector(".first-level-container");
    this.secondLevelContainer = this.el.querySelector(".second-level-container");
    this.firstLevelContent = this.el.querySelector(".first-level-content");
    this.secondLevelContent = this.el.querySelector(".second-level-content");
    this.supportAnchor = this.el.querySelector(".support-anchor");
    this.subjectContainer = document.querySelector(".subject-container");

    this.firstLevelQuestions = this.firstLevelContent.querySelectorAll("p");
    this.alertText = this.el.querySelector(".alert-text");
    this.contactList = document.querySelector(".contact-list");
    this.contactListContainer = this.contactList.querySelector(".contact-list-container");
    this.contactTabs = document.querySelector(".contact-tab");
    this.chooseSubject =
      this.firstLevelSelected.dataset.selecttext != null
        ? this.firstLevelSelected.dataset.selecttext
        : "Vælg emne";
    this.readmore =
      this.firstLevelSelected.dataset.readmore != null
        ? this.firstLevelSelected.dataset.readmore
        : "Læs mere";
    this.toggleQuestions = this.toggleQuestions.bind(this);
    this.selectQuestion = this.selectQuestion.bind(this);
    this.generateResult = this.generateResult.bind(this);
    this.buildResult = this.buildResult.bind(this);
    this.scrollToContact = this.scrollToContact.bind(this);
    this.subjectField = document.querySelector("#subject");
    this.messageField = document.querySelector("#message");
    this.mailFirstLevel = document.querySelector(".field-container .first-level-selected");
    this.mailSecondLevel = document.querySelector(".field-container .second-level-selected");
    this.mailSecondLevelSupport = document.querySelector(".field-container .second-level-support");
    this.textArea = document.querySelector(".field-text-area");

    this.init();
  }

  init() {
    this.firstLevelBox.addEventListener("click", event => {
      if (this.el.classList.contains("section-padding-bottom")) {
        this.el.classList.remove("section-padding-bottom");
      }
      this.toggleQuestions(
        this.firstLevelBox,
        this.firstLevelContainer,
        this.firstLevelContent
      );
    });

    this.secondLevelBox.addEventListener("click", event => {
      this.toggleQuestions(
        this.secondLevelBox,
        this.secondLevelContainer,
        this.secondLevelContent
      );
    });

    this.firstLevelQuestions.forEach(element => {
      element.addEventListener("click", event => {
        this.selectQuestion(element);
      });
    });

    if (this.supportAnchor) {
      this.supportAnchor.addEventListener("click", event => {
        this.scrollToContact();
      });
    }
  }

  scrollToContact() {
    var scrollToPosition = this.contactTabs.offsetTop;
    window.scrollTo(0, scrollToPosition);
  }

  selectQuestion(question) {
    this.firstLevelSelected.innerHTML = question.innerHTML;
    this.firstLevelBox.classList.add("selected");
    this.firstLevelContainer.style.height = "";
    this.firstLevelBox.classList.remove("open");
    this.generateResult(question.dataset.value, "one");
    if (question.innerHTML.toLowerCase() != 'andet'){
      this.subjectField.removeAttribute("required");
      this.subjectField.value = question.innerHTML;
      this.mailFirstLevel.innerHTML = question.innerHTML;
      this.subjectContainer.classList.remove("active");  
    }else{
      this.subjectField.setAttribute("required","");
      this.mailFirstLevel.innerHTML = question.innerHTML;
      this.subjectContainer.classList.add("active");
      this.subjectField.value = "";
    }
  }

  selectSecondQuestion(question) {
    this.secondLevelSelected.innerHTML = question.innerHTML;
    this.secondLevelBox.classList.add("selected");
    this.secondLevelContainer.style.height = "";
    this.secondLevelBox.classList.remove("open");
    this.generateResult(question.dataset.value, "two");
    this.subjectField.value = this.firstLevelSelected.innerHTML + ", " + question.innerHTML;
    this.mailSecondLevel.innerHTML = question.innerHTML;
  }

  toggleQuestions(box, container, content) {
    if (box.classList.contains("open")) {
      container.style.height = "";
      box.classList.remove("open");
    } else {
      var contentHeight = content.offsetHeight + "px";
      container.style.height = contentHeight;
      box.classList.add("active");
      box.classList.add("open");
    }
    this.el.classList.remove("section-padding");
  }

  generateResult(id, level) {
    let data = {
      id: id
    };
    fetch(`/umbraco/api/contact/getquestion`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.buildResult(json, level);
      })
      .catch(e => {});
  }

  buildResult(json, level) {
    if (level == "one") {
      this.supportAnchor.classList.add("active");
      this.contactTabs.classList.add("active");
      this.secondLevelSelected.innerHTML = this.chooseSubject;
      this.secondLevelBox.classList.remove("active");
      this.secondLevelBox.classList.remove("selected");
    }
    if (json.richMessage != null) {
      this.alertText.classList.add("active");
      this.alertText.innerHTML = json.richMessage;      
    } else {
      this.alertText.classList.remove("active");
      this.alertText.innerHTML = "";
    }
    if (json.furtherQuestions.length > 0 && level == "one") {
      this.secondLevelSupport.classList.add("active");
      this.mailSecondLevelSupport.classList.add("active");
      const htmlTemplate = json.furtherQuestions
        .map(item => {
          return `
          <p data-value="${item.id}">${item.title}</p>
        `;
        })
        .join("");
      this.secondLevelContent.innerHTML = htmlTemplate;
      var questions = this.secondLevelContent.querySelectorAll("p");
      questions.forEach(element => {
        element.addEventListener("click", event => {
          this.selectSecondQuestion(element);
        });
      });
    } else if (level == "one") {
      this.secondLevelSupport.classList.remove("active");
      this.mailSecondLevelSupport.classList.remove("active");
      this.secondLevelContent.innerHTML = "";
    }
    if (json.links.length > 0) {
      let counter = 0;
      let offset = "";
      const htmlListTemplate = json.links
        .map(item => {
          counter++;
          if (counter % 2 != 0) {
            offset = "col-md-offset-2 col-lg-offset-4";
          } else {
            offset = "col-md-offset-0 col-lg-offset-0";
          }
          return `
        <div class="col-xs-10 col-xs-offset-1 col-md-3 col-lg-3 ${offset}">
        <a href="${item.url}" aria-label="link til ${item.title}" class="link-box color-petroleum">
        <div class="link-box-container ">
        <div class="arrow-icon-container">
        <div class="arrow-icon">
        <svg class="svg-arrow-right">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-arrow-right"></use>
        </svg>
        </div>
        </div>
        <p class="link-text">${item.title}</p>
        <p class="link-more color-dark-green">${this.readmore}</p>
        </div>
        </a>
        </div>
        `;
        })
        .join("");
      this.contactList.classList.add("active");
      this.contactListContainer.innerHTML = htmlListTemplate;
    } else {
      this.contactList.classList.remove("active");
      this.contactListContainer.innerHTML = "";
    }
  }
}
