import {
  getCookieAcceptance,
  setCookie,
  getCookieValue
} from "../../services/service-cookies";

export default class ZipPopUp {
  constructor(element) {
    this.el = element;
    this.siteName = this.el.dataset.site;
    this.zipInput = this.el.querySelector("input");
    this.zipButton = this.el.querySelector("button");
    this.body = document.querySelector("body");
    this.error = this.el.querySelector(".error-text");
    this.saveZip = this.saveZip.bind(this);
    this.validateZip = this.validateZip.bind(this);
    this.init();
  }

  init() {
    let currentZip = getCookieValue("evidaZipCode");
    const postnummerRegEx = /^[0-9]{4}$/;

    if (!currentZip) {
      this.body.classList.add("active-popup");
      this.el.classList.add("show-popup");

      this.zipInput.addEventListener("input", event => {
        if (postnummerRegEx.test(this.zipInput.value)) {
          this.zipButton.disabled = false;
        } else {
          this.zipButton.disabled = true;
        }
      });

      this.zipInput.addEventListener("keyup", event => {
        if (event.keyCode === 13) {
          if (postnummerRegEx.test(this.zipInput.value)) {
            let myZip = this.zipInput.value;
            this.validateZip(myZip);
          }
        }
      });

      this.zipButton.addEventListener("click", event => {
        let myZip = this.zipInput.value;
        this.validateZip(myZip);
      });
    }
  }

  validateZip(zipcode) {
    let data = {
      postalCode: zipcode
    };

    fetch(`/umbraco/api/areas/getareaforpostalcode?site=` + this.siteName, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.id > 0) {
          this.error.classList.remove("activate");
          this.saveZip(zipcode);
        } else {
          this.error.classList.add("activate");
        }
      })
      .catch(e => {});
  }

  saveZip(zipcoden) {
    setCookie("evidaZipCode", zipcoden, 7);
    setCookie("evidaHelpCookie", true);

    window.location.reload(true);
    this.body.classList.remove("active-popup");
    this.el.classList.remove("show-popup");
  }
}
