export default class ContactForm {
  constructor(element) {
    this.el = element;

    this.site = this.el.dataset.site;

    this.toggleButtons = this.el.querySelectorAll(".cta-tab");
    this.toggleSection = this.toggleSection.bind(this);
    this.callSection = this.el.querySelector(".contact-call-section");
    this.postSection = this.el.querySelector(".contact-form-section");
    this.fileContainer = this.el.querySelector(".upload-files");
    this.validateEmail = this.validateEmail.bind(this);
    this.canPost = this.canPost.bind(this);
    this.postForm = this.postForm.bind(this);
    this.attachments = [];

    this.name = this.el.querySelector("#name");
    this.errorName = this.el.querySelector(".error-name");
    this.email = this.el.querySelector("#email");
    this.errorEmail = this.el.querySelector(".error-email");
    this.phone = this.el.querySelector("#phone");
    this.contact = this.el.querySelector("#contact");
    this.address = this.el.querySelector("#address");
    this.errorAddress = this.el.querySelector(".error-address");
    this.city = this.el.querySelector("#city");
    this.errorCity = this.el.querySelector(".error-city");
    this.zip = this.el.querySelector("#zip");
    this.errorZip = this.el.querySelector(".error-zip");
    this.subject = this.el.querySelector("#subject");
    this.message = this.el.querySelector("#message");
    this.files = this.el.querySelector("#files");
    this.samtygge = this.el.querySelector("#samtygge");
    this.gdpr = this.el.querySelector("#gdpr");
    this.errorGdpr = this.el.querySelector(".error-gdpr");
    this.future = this.el.querySelector("#future");
    this.send = this.el.querySelector(".cta-send");
    this.sectionId = this.send.dataset.sectionid;
    this.contactFormRow = this.el.querySelector(".contact-form-row");
    this.contactReceiptRow = this.el.querySelector(".contact-receipt-row");
    this.errorPopUp = this.el.querySelector(".post-error-module");
    this.closeError = this.el.querySelector("[data-closeposterror]");
    this.validateForm = this.validateForm.bind(this);
    this.spinner = this.el.querySelector(".form-spinner-overlay");

    this.init();
  }

  init() {
    if (this.toggleButtons) {
      this.toggleButtons.forEach((button) => {
        button.addEventListener("click", (event) => {
          this.toggleSection(button);
        });
      });
    }

    this.files.addEventListener("change", (event) => {
      if (this.files.files.length > 0) {
        this.fileContainer.classList.add("active");

        const formData = new FormData();
        const files = this.files.files;

        for (let i = 0; i < this.files.files.length; i++) {
          if (this.files.files[i].size > 10500000) {
            alert("filen: " + this.files.files[i].name + " er for stor");
            return false;
          } else {
            formData.append("files", this.files.files[i]);
          }
        }

        fetch("/umbraco/api/contact/uploadmedia", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            this.attachments = this.attachments.concat(result);
          });
      }
      Array.from(this.files.files).forEach((file) => {
        this.fileContainer.innerHTML = this.fileContainer.innerHTML + "<p>Fil: " + file.name + "</p>";
      });
    });

    this.name.addEventListener("change", (event) => {
      if (this.name.value.length > 0 && this.errorName.classList.contains("active")) {
        this.errorName.classList.remove("active");
      }
    });

    this.email.addEventListener("change", (event) => {
      if (this.email.value.length > 0 && this.errorEmail.classList.contains("active") && this.validateEmail(this.email.value)) {
        this.errorEmail.classList.remove("active");
      }
    });

    this.address.addEventListener("change", (event) => {
      if (this.address.value.length > 0 && this.errorAddress.classList.contains("active")) {
        this.errorAddress.classList.remove("active");
      }
    });

    this.city.addEventListener("change", (event) => {
      if (this.city.value.length > 0 && this.errorCity.classList.contains("active")) {
        this.errorCity.classList.remove("active");
      }
    });

    this.zip.addEventListener("change", (event) => {
      if (this.zip.value.length > 0 && this.errorZip.classList.contains("active")) {
        this.errorZip.classList.remove("active");
      }
    });

    this.gdpr.addEventListener("change", (event) => {
      if (this.gdpr.checked && this.errorGdpr.classList.contains("active")) {
        this.errorGdpr.classList.remove("active");
      }
    });

    this.send.addEventListener("click", (event) => {
      event.preventDefault();
      this.validateForm();
    });

    this.closeError.addEventListener("click", (event) => {
      event.preventDefault();
      this.errorPopUp.classList.remove("show-popup");
    });
  }

  validateForm() {
    var requiredFields = this.el.querySelectorAll("[required]");
    var canPostForm = true;
    requiredFields.forEach((field) => {
      var fieldID = field.id;
      var errorText = ".error-" + fieldID;
      var errorbox = this.el.querySelector(errorText);
      if (!field.validity.valid) {
        canPostForm = false;
        errorbox.classList.add("active");
      } else {
        errorbox.classList.remove("active");
      }
    });

    for (let i = 0; i < requiredFields.length; i++) {
      if (!requiredFields[i].validity.valid) {
        requiredFields[i].scrollIntoView({ block: "center" });
        break;
      }
    }

    if (canPostForm) {
      this.postForm();
    }
  }

  toggleSection(button) {
    let buttonData = button.dataset.section;

    this.toggleButtons.forEach((allbutton) => {
      allbutton.classList.remove("active");
    });
    button.classList.add("active");
    if (buttonData == "call") {
      this.postSection.classList.remove("active");
      this.callSection.classList.add("active");
    }
    if (buttonData == "post") {
      this.postSection.classList.add("active");
      this.callSection.classList.remove("active");
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  canPost() {
    if (this.hasName && this.hasEmail && this.hasPhone && this.hasGdpr) {
      this.send.classList.remove("inactive");
    } else {
      if (!this.send.classList.contains("inactive")) {
        this.send.classList.add("inactive");
      }
    }
  }

  postForm() {
    var body = {
      name: this.name.value,
      email: this.email.value,
      message: this.message.value,
      attachments: this.attachments,
      phone: this.phone.value,
      permitReplyByPhone: this.contact.checked,
      address: this.address.value,
      city: this.city.value,
      zipCode: this.zip.value,
      subject: this.subject.value,
      consentSavePhoneAndEmail: this.samtygge.checked,
      consentGDPR: this.gdpr.checked,
      sectionId: this.sectionId,
      site: this.site,
    };

    if (this.future) {
      body.consentSurveyContact = this.future.checked;
    }

    this.send.classList.add("hidden");
    this.spinner.classList.add("active");

    fetch(" /umbraco/api/contact/submitcontactrequest", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((result) => {
        if (!result.ok) {
          throw result;
        }
        this.contactFormRow.classList.remove("active");
        this.contactReceiptRow.classList.add("active");
        var scrollOptions = {
          behavior: "smooth",
          block: "center",
        };
        this.contactReceiptRow.scrollIntoView(scrollOptions);
      })
      .catch((error) => {
        this.errorPopUp.classList.add("show-popup");
      });
  }
}
