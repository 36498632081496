export default class VimeoComponent {
  constructor(element) {
    this.el = element;
    this.playButton = this.el.querySelector(".video-play");
    this.playVideo = this.playVideo.bind(this);
    this.pauseVideo = this.pauseVideo.bind(this);
    this.loadVideo = this.loadVideo.bind(this);
    this.videoContainer = this.el.querySelector(".video-player");
    this.videoID = this.videoContainer?this.videoContainer.dataset.vimeoId:``;
    this.videoCover = this.el.querySelector(".video-cover");
    this.videoEmbed = this.el.querySelector(".embed-container");
    this.observeOnScroll = this.observeOnScroll.bind(this);
    this.init();
  }

  init() {
    if (this.videoID) {
      this.loadVideo(this.videoID);
      this.observeOnScroll(this.el, this.videoID);
    }
  }

  loadVideo(videoID) {
    var isLoaded = false;
    var options = {
      id: videoID,
      loop: false
    };
    var videoContainer = "video-" + videoID;
    var player = new Vimeo.Player(videoContainer, options);

    player.on("loaded", function() {
      isLoaded = true;
    });

    if (this.playButton) {
      this.playButton.addEventListener("click", event => {
        this.videoCover.classList.add("active");
        this.videoEmbed.classList.add("active");
        player.play();
      });
    } else {
      this.videoEmbed.classList.add("active");
    }
  }

  playVideo(videoID) {
    if (this.videoCover) {
      this.videoCover.classList.add("active");
    }
    this.videoEmbed.classList.add("active");
    var options = {
      id: videoID,
      loop: false
    };
    var videoContainer = "video-" + videoID;

    var player = new Vimeo.Player(videoContainer, options);

    player.play();
  }

  observeOnScroll(element, videoID) {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio * 100 < 50) {
            this.pauseVideo(videoID);
          }
        });
      },
      {
        threshold: [0, 0.25, 0.5, 0.75, 1]
      }
    );

    observer.observe(element);
  }

  pauseVideo(videoID) {
    var videoContainer = "video-" + videoID;
    var PausePlayer = new Vimeo.Player(videoContainer);

    PausePlayer.getPaused()
      .then(function(paused) {
        if (!paused) {
          PausePlayer.pause();
        }
      })
      .catch(function(error) {
        // an error occurred
      });
  }
}
