import { isDesktop, detectOverlap } from "../../services/service-utils";

export default class SupportPage {
  constructor(element) {
    this.body = document.querySelector("body");
    this.el = element;
    this.atTop = window.scrollY === 0;
    this.toTop = this.el.querySelector(".to-top");
    this.menu = this.el.querySelector(".support-menu");
    this.footer = document.querySelector("footer");
    this.hideToTop = this.hideToTop.bind(this);
    this.scrollToAnchor = this.scrollToAnchor.bind(this);
    this.animatedElement = this.el.querySelectorAll(".animate-section");
    this.init();
  }

  init() {
    var target = window.location.hash,
      target = target.replace("#", "");

    // delete hash so the page won't scroll to it
    window.location.hash = "";
    var allAnimated = new Promise((resolve, reject) => {
      this.animatedElement.forEach((element, index, array) => {
        element.classList.add("animation-done");
        if (index === array.length - 1) resolve();
      });
    });

    allAnimated.then(() => {
      if (target) {
        this.scrollToAnchor(target);
      }
    });

    if (this.toTop) {
      this.toTop.addEventListener("click", event => {
        event.preventDefault();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      });
    }
    if (isDesktop()) {
      var myrec = this.toTop.getBoundingClientRect();

      if (
        detectOverlap(this.toTop, this.menu) ||
        detectOverlap(this.toTop, this.footer)
      ) {
        this.hideToTop(true);
      }

      let resizeTimer = {};
      window.addEventListener("scroll", event => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(
          function() {
            if (
              detectOverlap(this.toTop, this.menu) ||
              detectOverlap(this.toTop, this.footer)
            ) {
              this.hideToTop(true);
            } else {
              this.hideToTop(false);
            }
          }.bind(this),
          150
        );
      });
    }
  }

  scrollToAnchor(target) {
    window.onload = function() {
      if (target) {
        var anchor = this.el.querySelector("[name='" + target + "']");
        anchor.scrollIntoView({ inline: "start" });
      }
    }.bind(this);
    let url = document.location;
    url.hash = target;
  }

  hideToTop(state) {
    if (state) {
      this.toTop.classList.add("hide");
    } else {
      this.toTop.classList.remove("hide");
    }
  }
}
