import { getCookieValue, setCookie } from "../../services/service-cookies";
import { isMobile } from "../../services/service-utils";

export default class AlertComponent {
  constructor(element) {
    this.body = document.querySelector("body");
    this.header = document.querySelector(".header-container");
    this.siteWrapper = document.querySelector(".site-wrapper");
    this.main = document.querySelector("main");
    this.el = element;
    this.alertContainer = this.el.querySelector(".alert-component-container");

    this.closeAlertButton = this.el.querySelector(
      ".alert-component .close-container"
    );
    this.alertId = this.el.dataset.alertid;
    this.alertArea = this.el.dataset.alertarea;
    this.body = document.querySelector("body");
    this.closeAlert = this.closeAlert.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.headerHightCalculator = this.headerHightCalculator.bind(this);
    this.init();
  }

  init() {
    this.closeAlertButton.addEventListener("click", event => {
      this.closeAlert(this.alertId);
    });

    let resizeTimer = setTimeout(
      function() {
        this.showAlert(this.alertId);
      }.bind(this),
      500
    );

    window.addEventListener("resize", event => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(
        function() {
          this.showAlert(this.alertId);
        }.bind(this),
        250
      );
    });
  }

  showAlert(id) {
    let hiddenCookies = getCookieValue("evidaHideAlert");
    let aradID = getCookieValue("evidaAreaCode");
    let cookieArray = [];
    let alertContainerHeight = this.alertContainer.offsetHeight;

    if (hiddenCookies) {
      let cookieIDs = hiddenCookies.split(",");
      cookieIDs.forEach(element => {
        cookieArray.push(element);
      });

      if (
        !cookieArray.includes(id) &&
        (!this.alertArea || this.alertArea == aradID)
      ) {
        this.el.style.height = alertContainerHeight + "px";
        this.el.classList.remove("hide-alert");
        this.headerHightCalculator(this.header);
      }
    } else if (!this.alertArea || this.alertArea == aradID) {
      this.el.style.height = alertContainerHeight + "px";
      this.el.classList.remove("hide-alert");
      this.headerHightCalculator(this.header);
    }
  }

  closeAlert(id) {
    this.el.style.height = "0px";
    this.el.classList.add("hide-alert");
    let hiddenCookies = id;
    if (getCookieValue("evidaHideAlert")) {
      hiddenCookies = getCookieValue("evidaHideAlert") + "," + id;
    }
    setCookie("evidaHideAlert", hiddenCookies);
    this.headerHightCalculator(this.header);
  }

  headerHightCalculator(element) {
    let resizeTimer = {};
    let mobilePadding = this.siteWrapper.classList.contains(
      "active-selfservice"
    )
      ? 110
      : 80;
    let desktopPadding = this.siteWrapper.classList.contains(
      "active-selfservice"
    )
      ? 183
      : 164;
    let thisThreshold = isMobile() ? mobilePadding : desktopPadding;

    resizeTimer = setTimeout(
      function() {
        let newHeight =
          element.offsetHeight < thisThreshold
            ? thisThreshold
            : element.offsetHeight;

        this.main.style.paddingTop = newHeight + "px";
      }.bind(this),
      500
    );

    window.addEventListener("resize", event => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(
        function() {
          let newHeight =
            element.offsetHeight < thisThreshold
              ? thisThreshold
              : element.offsetHeight;

          this.main.style.paddingTop = newHeight + "px";
        }.bind(this),
        250
      );
    });
  }
}
