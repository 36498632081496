import { getCookieValue, setCookie } from "../../services/service-cookies";

export default class RedirectPopUp {
  constructor(element) {
    this.el = element;
    this.site = this.el.dataset.site;

    this.body = document.querySelector("body");
    this.zipInput = this.el.querySelector("input");
    this.zipButton = this.el.querySelector("button");
    this.error = this.el.querySelector(".error-text");
    this.myEvida = document.querySelector(".mybutton");

    this.repport = document.querySelector(".self-repporting");

    this.redirectMyEvida = this.redirectMyEvida.bind(this);
    this.saveZip = this.saveZip.bind(this);
    this.validateZip = this.validateZip.bind(this);
    this.button = "";
    this.init();
  }

  init() {
    const postnummerRegEx = /^[0-9]{4}$/;

    this.zipInput.addEventListener("input", event => {
      if (postnummerRegEx.test(this.zipInput.value)) {
        this.zipButton.disabled = false;
      } else {
        this.zipButton.disabled = true;
      }
    });

    this.zipInput.addEventListener("keyup", event => {
      if (event.keyCode === 13) {
        if (postnummerRegEx.test(this.zipInput.value)) {
          let myZip = this.zipInput.value;
          this.validateZip(myZip);
        }
      }
    });

    this.zipButton.addEventListener("click", event => {
      let myZip = this.zipInput.value;
      this.validateZip(myZip);
    });

    this.myEvida.addEventListener("click", event => {
      event.preventDefault();
      this.button = "myevida";
      this.redirectMyEvida(this.button);
    });

    this.repport.addEventListener("click", event => {
      event.preventDefault();
      this.button = "repport";
      this.redirectMyEvida();
    });
  }

  redirectMyEvida() {

    var zipcode = getCookieValue("evidaZipCode");

    if (zipcode != null) {
      let data = {
        postalCode: zipcode
      };

      fetch(`/umbraco/api/areas/getareaforpostalcode?site=${this.site}`, {
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.id > 0) {
            this.body.classList.remove("active-popup");
            this.el.classList.remove("show-popup");
            if (this.button == "myevida") {
              window.location = json.loginUrl;
            } else {
              window.location = json.reportingUrl;
            }
          } else {
            this.body.classList.add("active-popup");
            this.el.classList.add("show-popup");
          }
        })
        .catch(e => {});
    } else {
      this.body.classList.add("active-popup");
      this.el.classList.add("show-popup");
    }
  }

  validateZip(zipcode) {
    let data = {
      postalCode: zipcode
    };

    fetch(`/umbraco/api/areas/getareaforpostalcode?site=${this.site}`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.id > 0) {
          this.error.classList.remove("activate");
          this.saveZip(zipcode);
        } else {
          this.error.classList.add("activate");
        }
      })
      .catch(e => {});
  }

  saveZip(zipcoden) {
    setCookie("evidaZipCode", zipcoden, 7);
    setCookie("evidaHelpCookie", true);
    this.redirectMyEvida();
  }
}
