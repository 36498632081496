export default class ShareComponent {
  constructor(element) {
    this.el = element;
    this.twitter = this.el.querySelector(".twitter-link");
    this.linkedIn = this.el.querySelector(".linkedin-link");
    this.facebooklink = this.el.querySelector(".facebook-link");
    this.tweet = this.tweet.bind(this);
    this.link = this.link.bind(this);
    this.facebook = this.facebook.bind(this)
    this.init();
  }

  init() {
    if (this.twitter) {
      this.twitter.addEventListener("click", event => {
        event.preventDefault();
        this.tweet();
      });
    }

    if (this.linkedIn) {
      this.linkedIn.addEventListener("click", event => {
        event.preventDefault();
        this.link();
      });
    }

    if (this.facebooklink){
      this.facebooklink.addEventListener("click", event =>{
        event.preventDefault();
        this.facebook();
      })
    }
  }

  facebook(){
    console.log('window.location.href: ', window.location.href)
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" +
      `${window.location.href}`
      ,""
      ,"menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600,top=0,left=50%"
    )
  }

  tweet() {
    window.open(
      "https://twitter.com/share?url=" +
        escape(window.location.href) +
        "&text=" +
        document.title,
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600,top=0,left=50%"
    );
    return false;
  }

  link() {
    window.open(
      "https://www.linkedin.com/shareArticle?mini=true&url=" +
        window.location.href +
        "&title=" +
        escape(document.title) +
        "&source=Evida.dk",
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600,top=0"
    );
    return false;
  }
}
