export default class GlobalSearch {
  constructor(element) {
    this.el = element;
    this.searchButton = this.el.querySelector(".search-button");
    this.searchField = this.el.querySelector("#search");
    this.searchPageUrl = this.el.dataset.searchUrl;

    this.searchSite = this.searchSite.bind(this);
    this.init();
  }

  init() {
    this.searchButton.addEventListener("click", event => {
      if (this.searchField.value.length > 0) {
        this.searchSite(this.searchField.value);
      } else {
        this.searchSite("");
      }
    });

    this.searchField.addEventListener("keyup", event => {
      if (event.keyCode === 13) {
        this.searchSite(this.searchField.value);
      }
    });
  }

  searchSite(query) {
    console.log(this.searchPageUrl);
    const params = new URLSearchParams(location.search);
    if (params.get("page") != null) {
      params.delete("page");
    }
    if (query.length > 0) {
      params.set("query", query);
    } else {
      params.delete("query");
    }
    window.location.replace(this.searchPageUrl + "?" + params.toString());
  }
}
