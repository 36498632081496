export default class ImageContainer {
  constructor(element) {
    this.el = element;
    this.image = this.el.querySelectorAll("img[data-img]");
    this.picture = this.el.querySelectorAll(".picture-container");
    this.lazyLoadImage = this.lazyLoadImage.bind(this);
    this.lazyLoadPicture = this.lazyLoadPicture.bind(this);
    this.init();
  }

  init() {
    if (this.image.length > 0) {
      this.lazyLoadImage(this.image);
    }
    if (this.picture.length > 0) {
      this.lazyLoadPicture(this.picture);
    }
  }

  lazyLoadImage(img) {
    let lazyOptions = {
      rootMargin: "0px 0px 200px 0px",
      threshold: [0],
      numSteps: 1
    };
    const imageObserver = new IntersectionObserver((entries, imgObserver) => {
      entries.forEach(
        entry => {
          if (entry.isIntersecting) {
            const lazyImage = entry.target;
            lazyImage.src = lazyImage.dataset.img;
            if (lazyImage.dataset.srcset) {
              lazyImage.srcset = lazyImage.dataset.srcset;
            }
            if (lazyImage.dataset.sizes) {
              lazyImage.sizes = lazyImage.dataset.sizes;
            }
            lazyImage.classList.add("lzy_img");
            imgObserver.unobserve(lazyImage);
          }
        },
        { lazyOptions }
      );
    });

    img.forEach(element => {
      imageObserver.observe(element);
    });
  }

  lazyLoadPicture(picture) {
    let lazyOptions = {
      rootMargin: "0px 0px 200px 0px",
      threshold: [0],
      numSteps: 1
    };
    const imageObserver = new IntersectionObserver((entries, imgObserver) => {
      entries.forEach(
        entry => {
          if (entry.isIntersecting) {
            const lazyImage = entry.target;
            const lazySrc = lazyImage.querySelectorAll("source");
            const defaultImage = lazyImage.querySelector("img");
            lazySrc.forEach(image => {
              if (image.dataset.srcset) {
                image.srcset = image.dataset.srcset;
              }
            });
            defaultImage.src = defaultImage.dataset.img;
            if (defaultImage.dataset.srcset) {
              defaultImage.srcset = defaultImage.dataset.srcset;
            }
            if (defaultImage.dataset.sizes) {
              defaultImage.sizes = lazyImage.dataset.sizes;
            }

            defaultImage.classList.add("lzy_img");
            imgObserver.unobserve(lazyImage);
          }
        },
        { lazyOptions }
      );
    });

    picture.forEach(element => {
      imageObserver.observe(element);
    });
  }
}
