export default class SupportMenu {
  constructor(element) {
    this.el = element;
    this.container = document.querySelector(".support-page")
    this.init();
  }

  init() {
    console.log("init");
    let height = this.container.offsetHeight - window.innerHeight;
    
    var controller = new ScrollMagic.Controller();
    var pinMenuToPage = new ScrollMagic.Scene({
      triggerElement: this.el,
      triggerHook:0,
      duration:height
    })
    .setPin(this.el)
    .addTo(controller)
  }
}
