export default class AnimateSection {
  constructor(element) {
    this.el = element;
    this.sections = this.el.querySelectorAll(".animate-section");
    this.observeOnScroll = this.observeOnScroll.bind(this);
    this.atTop = window.scrollY === 0;
    this.init();
  }

  init() {
    if (location.hash.length == 0) {
      this.observeOnScroll();
    } else {
      this.animateAll();
    }
  }

  animateAll() {
    this.sections.forEach(section => {
      section.classList.add("animation-done");
    });
  }

  observeOnScroll() {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry, index) => {
        setTimeout(() => {
          if (this.atTop) {
            if (entry.intersectionRatio > 0) {
              entry.target.classList.add("animation-done");
              if (entry.target.classList.contains("animation-done")) {
                observer.unobserve(entry.target);
              }
            }
          }
        }, 250 * index);

        if (!this.atTop) {
          if (entry.intersectionRatio > 0) {
            entry.target.classList.add("animation-done");
            if (entry.target.classList.contains("animation-done")) {
              observer.unobserve(entry.target);
            }
          }
        }
      });
    });

    // start observing your element
    this.sections.forEach(section => {
      observer.observe(section);
    });
  }
}
