export default class Glider {
  constructor(element) {
    this.el = element;
    this.slides = this.el.querySelectorAll(".slide-bg");
    this.initGlider = this.initGlider.bind(this);
    this.setSlideHeight = this.setSlideHeight.bind(this);
    this.setBackground = this.setBackground.bind(this);
    this.init();
  }

  init() {
    this.slides.forEach(slide => {
      if (slide.dataset.bg != "") {
        this.setBackground(slide);
      }
    });
    this.setSlideHeight();
  }

  setSlideHeight() {
    var slideHeight = 0;

    this.slides.forEach(slide => {
      if (slide.offsetHeight > slideHeight) {
        slideHeight = slide.offsetHeight;
      }
    });

    this.slides.forEach(slide => {
      slide.style.height = slideHeight + "px";
    });

    this.initGlider();
  }

  setBackground(slide) {
    slide.style.backgroundImage = "url('" + slide.dataset.bg + "')";
  }

  initGlider() {
    var glide = new Glide(this.el, {
      type: "carousel",
      startAt: 0,
      perView: 1,
      hoverpause: true
    });
    glide.mount();
  }
}
