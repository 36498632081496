export default class AccordionComponent {
  constructor(element) {
    this.el = element;
    this.container = this.el.querySelectorAll(".accordion-headline");
    this.playAccordion = this.playAccordion.bind(this);
    this.init();
  }

  init() {
    this.container.forEach(element => {
      element.addEventListener("click", event => {
        this.playAccordion(element);
      });
    });
  }

  playAccordion(element) {
    let symbol = element.querySelector(".circle-plus");
    let toggler = element.nextElementSibling;

    let content = toggler.querySelector(".accordion-content");
    let contentHeight = content.offsetHeight;
    if (element.classList.contains("open")) {
      toggler.style.height = "";
      element.classList.remove("open");
      symbol.classList.remove("opened");
      symbol.classList.add("closed");
    } else {
      toggler.style.height = contentHeight + "px";
      element.classList.add("open");
      symbol.classList.remove("closed");
      symbol.classList.add("opened");
    }
  }
}
