import { EventBus, EventTypes } from "../../consts/const-event-types";
import {
  getCookieAcceptance,
  setCookie,
  getCookieValue,
  removeCookie
} from "../../services/service-cookies";

export default class HelpPopUp {
  constructor(element) {
    this.body = document.querySelector("body");
    this.el = element;
    this.openPopUpButton = this.el.querySelector(".help-popup-toggler");
    this.body = document.querySelector("body");
    this.openPopUp = this.openPopUp.bind(this);
    this.popUp = this.el.querySelector(".help-popup-container");
    this.popUpContainer = this.el.querySelector(".help-popup-content");
    this.closePopUpButton = this.el.querySelector(".close-container");
    //this.popUpForm = this.el.querySelector(".help-popup-form");
    //this.zipInput = this.popUpForm.querySelector("input");
    //this.zipButton = this.popUpForm.querySelector("button");
    //this.error = this.popUpContainer.querySelector(".error-text");
    this.closePopUp = this.closePopUp.bind(this);
    this.saveZip = this.saveZip.bind(this);
    this.validateZip = this.validateZip.bind(this);
    this.closePopUpNoZip = this.closePopUpNoZip.bind(this)
    this.site = this.el.dataset.site;

    this.init();
  }

  init() {
    const postnummerRegEx = /^[0-9]{4}$/;
    let currentZip = getCookieValue("evidaZipCode");
    let hasClosed = getCookieValue("evidaHelpCookie");
    //this.zipButton.disabled = true;

    // if (currentZip) {
    //   this.openPopUpButton.classList.add("hasZip");
    //   this.zipInput.value = currentZip;
    //   this.zipButton.disabled = false;
    // } else if (
    //   !hasClosed &&
    //   !this.body.classList.contains("compact-navigation") &&
    //   !getCookieAcceptance("evidaHelpCookie") &&
    //   !this.body.classList.contains("KundesupportSektion") &&
    //   !this.body.classList.contains("compact-navigation")
    // ) {
    //   this.openPopUp();
    // }
    this.openPopUpButton.addEventListener("click", event => {
      event.preventDefault();
      this.openPopUp();
    });

    this.closePopUpButton.addEventListener("click", event => {
      //this.closePopUp(this.zipInput.value);
      this.closePopUpNoZip();
    });

    // this.zipInput.addEventListener("input", event => {
    //   if (postnummerRegEx.test(this.zipInput.value)) {
    //     this.zipButton.disabled = false;
    //   } else {
    //     this.zipButton.disabled = true;
    //   }
    // });

    // this.zipButton.addEventListener("click", event => {
    //   let myZip = this.zipInput.value;
    //   this.validateZip(myZip);
    // });

    // this.zipInput.addEventListener("keyup", event => {
    //   if (event.keyCode === 13) {
    //     if (postnummerRegEx.test(this.zipInput.value)) {
    //       let myZip = this.zipInput.value;
    //       this.validateZip(myZip);
    //     }
    //   }
    // });
  }

  validateZip(zipcode) {
    let data = {
      postalCode: zipcode
    };

    fetch(`/umbraco/api/areas/getareaforpostalcode?site=` + this.site, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.id > 0) {
          this.error.classList.remove("activate");
          this.saveZip(zipcode);
        } else {
          this.error.classList.add("activate");
        }
      })
      .catch(e => {});
  }

  saveZip(zipcoden) {
    var refelalUrl = this.zipButton.dataset.url;
    setCookie("evidaZipCode", zipcoden, 367);
    setCookie("evidaHelpCookie", true);
    window.location.href = refelalUrl;
  }

  openPopUp() {
    this.popUp.classList.remove("closed");
    var containerHeight = this.popUpContainer.offsetHeight;
    this.popUp.style.height = containerHeight + "px";
  }

  closePopUp(zip) {
    if (zip.length < 1) {
      removeCookie("evidaZipCode");
      this.openPopUpButton.classList.remove("hasZip");
    }

    if (!getCookieAcceptance("evidaHelpCookie")) {
      setCookie("evidaHelpCookie", true);
    }
    this.popUp.style.height = "0px";

    this.popUp.classList.add("closed");
  }

  closePopUpNoZip(){
    this.popUp.style.height = "0px";

    this.popUp.classList.add("closed");
  }

}
