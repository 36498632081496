import { EventBus, EventTypes } from "../../consts/const-event-types";
import { isMobile } from "../../services/service-utils";

export default class Header {
  constructor(element) {
    this.el = element;
    this.body = document.querySelector("body");
    this.searchOpen = this.el.querySelector(".navigation-search");
    this.searchClose = this.el.querySelector(".close");
    this.searchBox = this.el.querySelector(".search-container");
    this.searchRow = this.searchBox.querySelector(".row");
    this.menuActivation = this.el.querySelector(".menulist-activation");
    this.menuList = this.el.querySelector(".menulist");
    this.menuListContainer = this.el.querySelector(".menulist-container");
    this.searchInput = this.el.querySelector("#search");

    this.closeMenu = this.el.querySelector(
      ".menulist-container .close-container"
    );
    this.OpenSearch = this.OpenSearch.bind(this);
    this.menuToggle = this.menuToggle.bind(this);
    this.onScroll = this.onScroll.bind(this);

    this.menuOpen = false;
    this.atTop = false;
    this.scrollingUp = false;
    this.showBgColor = false;
    this.lastScrollY = 0;
    this.deltaY = 0;
    this.posY = 0;
    this.menuHeight = isMobile() ? 64 : 254;

    this.init();
  }

  init() {
    console.log("header")
    let searchHeight = this.searchRow.offsetHeight;
    let resizeTimer = {};
    let observer = {};
    if (!isMobile()) {
      document.addEventListener("scroll", this.onScroll);

      this.onScroll();
    }

    var ro = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.menuHeight = this.el.offsetHeight;
      }
    });

    // Observe one or multiple elements
    ro.observe(this.el);

    window.addEventListener("resize", event => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(
        function() {
          searchHeight = this.searchRow.offsetHeight;

          if (this.el.classList.contains("open")) {
            this.OpenSearch(searchHeight);
          }
        }.bind(this),
        250
      );
    });

    this.searchOpen.addEventListener("click", event => {
      this.OpenSearch(searchHeight);
      this.searchInput.focus();
    });
    this.searchClose.addEventListener("click", event => {
      this.OpenSearch(0);
      this.searchInput.blur();
    });

    this.menuActivation.addEventListener("click", event => {
      this.menuToggle(event);
    });

    this.closeMenu.addEventListener("click", event => {
      this.menuToggle(event);
    });
  }

  OpenSearch(setHeight) {
    if (setHeight > 0 && !this.el.classList.contains("open")) {
      this.el.classList.add("open");
    } else {
      this.el.classList.remove("open");
      setHeight = 0;
    }
    this.searchBox.style.height = setHeight + "px";
  }

  menuToggle(e) {
    e.preventDefault();
    if (this.body.classList.contains("active-menu")) {
      this.body.classList.remove("active-menu");
      const scrollY = this.body.style.top;
      this.body.style.position = "";
      this.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    } else {
      this.body.classList.add("active-menu");
      this.body.position = "fixed";
      this.body.style.top = `-${window.scrollY}px`;
    }
  }

  onScroll() {
    this.atTop = window.pageYOffset === 0;
    this.scrollingUp = this.lastScrollY > window.pageYOffset;
    this.deltaY = window.pageYOffset - this.lastScrollY;
    this.posY -= window.pageYOffset > 0 ? this.deltaY : 0;
    if (this.body.classList.contains("compact-navigation")) {
      this.menuHeight = 129;
    }

    if (!this.scrollingUp) {
      if (this.posY < -(this.menuHeight + 5))
        this.posY = -(this.menuHeight + 45);
    } else {
      if (this.posY > 0) this.posY = 0;
    }

    if (window.matchMedia("(min-width: 767px)").matches) {
      this.el.style.transform = `translateY(${this.posY}px)`;
      //this.newMenu.style.transform = `translateY(${this.posY}px) translateX(-100%)`;
    }

    this.lastScrollY = window.pageYOffset;
  }
}
