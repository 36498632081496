import bodymovin from "../../../public/assets/js/bodymovin/lottie_player";

export default class FpHeader {
  constructor(element) {
    this.el = element;
    this.svgContainer = this.el.querySelector(".svg-container");
    this.main = document.querySelector("main");
    this.breadcrumb = document.querySelector(".breadcrumb-section");
    this.setHeight = this.setHeight.bind(this);
    this.hasAnimation = false;
    this.init();
  }

  init() {
    console.log("fpInit")
    let resizeTimer = {};

    if (this.svgContainer && !this.hasAnimation) {
      console.log('this.svgContainer: ', this.svgContainer);
      var animation = this.svgContainer.dataset.animation;
      var animdata = bodymovin.loadAnimation({
        container: this.svgContainer,
        renderer: "svg",
        autoplay: true,
        loop: true,
        path: this.svgContainer.dataset.animation
      });
      animdata.play(console.log("load animation"));
      this.setHeight();
      window.addEventListener("resize", event => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(
          function() {
            this.setHeight();
          }.bind(this),
          250
        );
      });
    }
    this.hasAnimation = true;
  }

  setHeight() {
    var styles = window.getComputedStyle(this.breadcrumb);
    var margin =
      parseFloat(styles["marginTop"]) + parseFloat(styles["marginBottom"]);
    var mainStyle = window.getComputedStyle(this.main);
    var topPadding =
      parseFloat(mainStyle["paddingTop"]) +
      parseFloat(mainStyle["paddingBottom"]);

    var addedHeight = topPadding + this.breadcrumb.offsetHeight + margin;
    this.el.style.height = "calc(100vh - " + addedHeight + "px)";
  }
}
