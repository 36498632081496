export default class ConnectionComponent {
  constructor(element) {
    this.el = element;
    this.gridid = this.el.dataset.gridid;
    this.nodeId = this.el.dataset.nodeid;
    this.title = this.el.dataset.formtitle;
    this.endStep = this.el.querySelector(".end-step");
    this.endStepDescription = this.el.querySelector(".end-step-description");
    this.slides = this.el.querySelectorAll("fieldset");
    this.initialSlide = this.el.querySelector(".center");
    this.initialSlideNr = this.initialSlide.dataset.slide;
    this.slideCounter = this.slides.length;
    this.initialStep = this.el.querySelector(
      "[data-step='" + this.initialSlideNr + "']"
    );
    this.prev = this.el.querySelector("[data-prev]");
    this.next = this.el.querySelector("[data-next]");
    this.steps = this.el.querySelectorAll(".connection-steps ul li");
    this.slideContainer = this.el.querySelector(".fieldset-container");
    this.navigation = this.el.querySelector(".connection-navigation");
    this.editLink = this.el.querySelectorAll(".edit");
    this.fileShowContainer = this.el.querySelector(".upload-files");
    this.floorplanSvg = this.el.querySelector(".floorplan-svg");
    this.attachments = [];

    this.address = this.el.querySelector("#address");
    this.zip = this.el.querySelector("#zip");
    this.city = this.el.querySelector("#city");
    this.businessname = this.el.querySelector("#businessname");
    this.businesscvr = this.el.querySelector("#businesscvr");
    this.businessean = this.el.querySelector("#businessean");
    this.businessaddress = this.el.querySelector("#businessaddress");
    this.businesszip = this.el.querySelector("#businesszip");
    this.businesscity = this.el.querySelector("#businesscity");
    this.businesscontactname = this.el.querySelector("#businesscontactname");
    this.businesscontactphone = this.el.querySelector("#businesscontactphone");
    this.businesscontactemail = this.el.querySelector("#businesscontactemail");
    this.advisername = this.el.querySelector("#advisername");
    this.adveserphone = this.el.querySelector("#adviserphone");
    this.adviseremail = this.el.querySelector("#adviseremail");
    this.heating = this.el.querySelector("#heating");
    this.processing = this.el.querySelector("#processing");
    this.forbrug = this.el.querySelector("#forbrug");
    this.forventedForbrug = this.el.querySelector("#forventedforbrug");
    this.effect = this.el.querySelector("#effect");
    this.files = this.el.querySelector("#files");

    this.dato = this.el.querySelector("#datepicker");
    this.postFormButton = this.el.querySelector(".post-form");
    this.dataRow = this.el.querySelectorAll(".data-row");

    this.addressContainer = this.el.querySelector("[data-address]");
    this.businessContainer = this.el.querySelector("[data-business]");
    this.contactContainer = this.el.querySelector("[data-contact]");
    this.adviserContainer = this.el.querySelector("[data-adviser]");
    this.purposeContainer = this.el.querySelector("[data-purpose]");
    this.consumptionContainer = this.el.querySelector("[data-consumption]");
    this.expectedContainer = this.el.querySelector("[data-expected]");
    this.effectContainer = this.el.querySelector("[data-effect]");
    this.fileContainer = this.el.querySelector("[data-file]");
    this.dateContainer = this.el.querySelector("[data-date]");

    this.moveSlide = this.moveSlide.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.confirmForm = this.confirmForm.bind(this);
    this.moveToSlide = this.moveToSlide.bind(this);
    this.postform = this.postform.bind(this);
    this.init();
  }

  init() {
    let minHeight = this.initialSlide.offsetHeight;
    this.slideContainer.style.height = minHeight + 40 + "px";
    this.initialStep.classList.add("active");

    this.next.addEventListener("click", event => {
      let thisActiveSlide = this.el.querySelector(".center");
      this.validateForm(thisActiveSlide);
    });

    this.prev.addEventListener("click", event => {
      this.moveSlide("backwards");
    });

    this.editLink.forEach(link => {
      link.addEventListener("click", event => {
        var slideNumber = Number(link.dataset.edit);
        this.moveToSlide(slideNumber);
      });
    });

    this.postFormButton.addEventListener("click", event => {
      this.postform();
    });

    this.files.addEventListener("change", event => {
      if (this.files.files.length > 0) {
        this.fileContainer.classList.add("active");
        const formData = new FormData();
        const files = this.files.files;
        for (let i = 0; i < this.files.files.length; i++) {
          if (this.files.files[i].size > 10500000) {
            alert("filen: " + this.files.files[i].name + " er for stor");
            return false;
          } else {
            formData.append("files", this.files.files[i]);
          }
        }

        fetch("/umbraco/api/contact/uploadmedia", {
          method: "POST",
          body: formData
        })
          .then(response => response.json())
          .then(result => {
            this.attachments = this.attachments.concat(result.UploadIds);
          });
      }
      Array.from(this.files.files).forEach(file => {
        this.floorplanSvg.classList.add("active");

        this.fileShowContainer.innerHTML =
          this.fileShowContainer.innerHTML + "<p>Fil: " + file.name + "</p>";
      });
    });
  }

  moveToSlide(slideNr) {
    slideNr = Number(slideNr);
    var slideToSlide = this.el.querySelector("[data-slide='" + slideNr + "']");

    this.slides.forEach(slide => {
      if (slide.dataset.slide > slideNr) {
        slide.classList.remove("left");
        slide.classList.remove("center");
        slide.classList.add("right");
      }
    });
    this.slideContainer.style.height = slideToSlide.offsetHeight + 40 + "px";
    this.navigation.style.display = "";
    slideToSlide.classList.add("center");
    slideToSlide.classList.remove("left");

    console.log("slideToSlide: ", slideToSlide);
    this.steps.forEach(step => {
      var stepNr = Number(step.dataset.step);
      if (stepNr == slideNr) {
        step.classList.add("active");
      } else {
        step.classList.remove("active");
      }
    });
    slideToSlide.scrollIntoView({ block: "center" });
  }

  validateForm(slide) {
    var requiredFields = slide.querySelectorAll("[required]");
    var canPostForm = true;
    requiredFields.forEach(field => {
      var fieldID = field.id;
      var errorText = ".error-" + fieldID;
      var errorbox = slide.querySelector(errorText);
      if (!field.validity.valid) {
        canPostForm = false;
        errorbox.classList.add("active");

        this.slideContainer.style.height = slide.offsetHeight + 40 + "px";
      } else {
        errorbox.classList.remove("active");
      }
    });

    for (let i = 0; i < requiredFields.length; i++) {
      if (!requiredFields[i].validity.valid) {
        break;
      }
    }

    if (canPostForm) {
      this.moveSlide("forward");
    }
  }

  moveSlide(direction) {
    let thisSlide = this.el.querySelector(".center");
    let required = thisSlide.querySelectorAll("[required]");
    let thisSlideNr = Number(thisSlide.dataset.slide);
    let nextSlideNr = thisSlideNr + 1;
    let prevSlideNr = thisSlideNr - 1;
    let thisStep = this.el.querySelector("[data-step='" + thisSlideNr + "']");

    if (direction === "forward" && nextSlideNr <= this.slideCounter) {
      let nextSlide = this.el.querySelector(
        "[data-slide='" + nextSlideNr + "']"
      );
      let thisHeader = nextSlide.querySelector(".h2");
      let nextStep = this.el.querySelector("[data-step='" + nextSlideNr + "']");
      this.slideContainer.style.height = nextSlide.offsetHeight + 40 + "px";
      thisStep.classList.remove("active");
      nextStep.classList.add("active");
      thisSlide.classList.add("left");
      thisSlide.classList.remove("center");
      nextSlide.classList.add("center");
      nextSlide.classList.remove("right");
      this.prev.classList.remove("inactive");
      if (nextSlideNr === this.slideCounter) {
        this.confirmForm();
        this.slideContainer.style.height = nextSlide.offsetHeight + 40 + "px";
        this.navigation.style.display = "none";
      }
      thisHeader.scrollIntoView({ block: "center" });
    }

    if (direction === "backwards" && prevSlideNr > 0) {
      let prevSlide = this.el.querySelector(
        "[data-slide='" + prevSlideNr + "']"
      );
      let thisHeader = prevSlide.querySelector(".h2");
      let prevStep = this.el.querySelector("[data-step='" + prevSlideNr + "']");
      this.slideContainer.style.height = prevSlide.offsetHeight + 40 + "px";
      thisStep.classList.remove("active");
      prevStep.classList.add("active");
      thisSlide.classList.add("right");
      thisSlide.classList.remove("center");
      prevSlide.classList.add("center");
      prevSlide.classList.remove("left");
      this.prev.classList.remove("inactive");
      thisHeader.scrollIntoView({ block: "center" });
      this.next.innerHTML = "Næste";

      if (prevSlideNr === 1) {
        this.prev.classList.add("inactive");
      }
    }
  }

  confirmForm() {
    const addressInfo = `<p>${this.address.value}<br/>${this.zip.value} ${this.city.value}</p>`;

    const businessInfo = `<p>${this.businessname.value}<br>CVR: ${
      this.businesscvr.value
    }${
      this.businessean.value != "" ? `<br/>EAN: ${this.businessean.value}` : ``
    }<br/>${this.businessaddress.value}<br>${this.businesszip.value} ${
      this.businesscity.value
    }</p>`;

    const contactInfo = `<p>${this.businesscontactname.value}<br/>${
      this.businesscontactemail.value
    }${
      this.businesscontactphone.value != ""
        ? `<br>${this.businesscontactphone.value}`
        : ``
    }</p>`;

    const AdvisorInfo = `<p>${this.advisername.value}<br/>${
      this.adveserphone.value
    }${this.adviseremail != "" ? `<br/>${this.adviseremail.value}` : ``}</p>`;

    const consumptionInfo = `<p>${
      this.heating.checked ? `Rumopvarmning` : `Process`
    }</p>`;

    const usageInfo = `<p>${
      this.forbrug.value != "" ? this.forbrug.value : `Ikke oplyst`
    }</p>`;
    const expectedInfo = `<p>${
      this.forventedForbrug.value != ""
        ? this.forventedForbrug.value
        : `Ikke oplyst`
    }</p>`;

    const effectInfo = `<p>${
      this.effect.value != "" ? this.effect.value : `Ikke oplyst`
    }</p>`;
    const fileInfo = `<p>${
      this.attachments.length > 0 ? `Tilføjet` : `Mangler`
    }</p>`;
    const dateInfo = `<p>${
      this.dato.value != "" ? this.dato.value : `Ikke oplyst`
    }`;

    this.addressContainer.innerHTML = addressInfo;
    this.businessContainer.innerHTML = businessInfo;
    this.contactContainer.innerHTML = contactInfo;
    this.adviserContainer.innerHTML = AdvisorInfo;
    this.purposeContainer.innerHTML = consumptionInfo;
    this.consumptionContainer.innerHTML = usageInfo;
    this.expectedContainer.innerHTML = expectedInfo;
    this.effectContainer.innerHTML = effectInfo;
    this.fileContainer.innerHTML = fileInfo;
    this.dateContainer.innerHTML = dateInfo;
  }

  postform() {
    const body = {
      title: this.title,
      originatingModuleId: this.gridid,
      originatingContentId: this.nodeId,
      fields: [
        {
          title: this.address.getAttribute("aria-label"),
          value: this.address.value,
          type: "string"
        },
        {
          title: this.zip.getAttribute("aria-label"),
          value: this.zip.value,
          type: "string"
        },
        {
          title: this.city.getAttribute("aria-label"),
          value: this.city.value,
          type: "string"
        },
        {
          title: this.businessname.getAttribute("aria-label"),
          value: this.businessname.value,
          type: "string"
        },
        {
          title: this.businesscvr.getAttribute("aria-label"),
          value: this.businesscvr.value,
          type: "string"
        },
        {
          title: this.businessean.getAttribute("aria-label"),
          value: this.businessean.value,
          type: "string"
        },
        {
          title:
            "Virksomhed " + this.businessaddress.getAttribute("aria-label"),
          value: this.businessaddress.value,
          type: "string"
        },
        {
          title: "Virksomhed " + this.businesszip.getAttribute("aria-label"),
          value: this.businesszip.value,
          type: "string"
        },
        {
          title: "Virksomhed " + this.businesscity.getAttribute("aria-label"),
          value: this.businesscity.value,
          type: "string"
        },
        {
          title:
            "Kontakt " + this.businesscontactname.getAttribute("aria-label"),
          value: this.businesscontactname.value,
          type: "string"
        },
        {
          title:
            "Kontakt " + this.businesscontactemail.getAttribute("aria-label"),
          value: this.businesscontactemail.value,
          type: "string"
        },
        {
          title:
            "Kontakt " + this.businesscontactphone.getAttribute("aria-label"),
          value: this.businesscontactphone.value,
          type: "string"
        },
        {
          title: "Rådgiver " + this.advisername.getAttribute("aria-label"),
          value: this.advisername.value,
          type: "string"
        },
        {
          title: "Rådgiver " + this.adveserphone.getAttribute("aria-label"),
          value: this.adveserphone.value,
          type: "string"
        },
        {
          title: "Rådgiver " + this.adviseremail.getAttribute("aria-label"),
          value: this.adviseremail.value,
          type: "string"
        },
        {
          title: "Rumopvarmning",
          value: this.heating.checked,
          type: "bool"
        },
        {
          title: "Process",
          value: this.processing.checked,
          type: "bool"
        },
        {
          title: this.forbrug.getAttribute("aria-label"),
          value: this.forbrug.value,
          type: "string"
        },
        {
          title: this.forventedForbrug.getAttribute("aria-label"),
          value: this.forventedForbrug.value,
          type: "string"
        },
        {
          title: this.effect.getAttribute("aria-label"),
          value: this.effect.value,
          type: "string"
        },
        {
          title: this.dato.getAttribute("aria-label"),
          value: this.dato.value,
          type: "string"
        }
      ],
      attachments: this.attachments
    };

    fetch("/umbraco/api/connectionform/submitform", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body)
    })
      .then(result => {
        if (!result.ok) {
          throw result;
        }
        this.editLink.forEach(edit => {
          edit.style.display = "none";
        });
        this.endStep.innerHTML = this.endStep.dataset.success;
        this.endStepDescription = this.endStepDescription.dataset.success;
        this.endStep.scrollIntoView({ block: "center" });
        this.postFormButton.style.display = "none";
      })
      .catch(error => {
        console.log("error");
        //this.errorPopUp.classList.add("show-popup");
      });
  }
}
