export default class SearchComponent {
  constructor(element) {
    this.el = element;
    this.resultList = this.el.querySelector(".search-result");
    this.resultText = this.el.querySelector(".result");
    this.pagingContainer = this.el.querySelector(".paging");
    this.searchField = this.el.querySelector("#searchpage");
    this.searchButton = this.el.querySelector(".cta-pagesearch");

    this.generateResult = this.generateResult.bind(this);
    this.searchSite = this.searchSite.bind(this);
    this.pagePerView =
      this.el.dataset.paging != null ? this.el.dataset.paging : 20;
    this.generatePager = this.generatePager.bind(this);
    this.usePager = this.usePager.bind(this);
    this.buildResult = this.buildResult.bind(this);
    
    this.siteName = this.el.dataset.siteName;

    this.init();
  }

  init() {
    const params = new URLSearchParams(location.search);
    if (params.get("query") != null) {
      this.searchSite(params.get("query"));
      if (this.searchField.value != params.get("query")) {
        this.searchField.value = params.get("query");
      }
    }

    this.searchButton.addEventListener("click", event => {
      if (this.searchField.value.length > 0) {
        this.searchSite(this.searchField.value);
      } else {
        this.searchSite("");
      }
    });

    this.searchField.addEventListener("keyup", event => {
      if (event.keyCode === 13) {
        this.searchSite(this.searchField.value);
      }
    });
  }

  generateResult() {
    const params = new URLSearchParams(location.search);
    let pagingParameter =
      params.get("page") != null
        ? this.pagePerView * (Math.trunc(params.get("page")) - 1)
        : 0;

    let searchQuery = params.get("query") != null ? params.get("query") : "";

    let data = {
      searchText: searchQuery,
      Take: this.pagePerView,
      Skip: pagingParameter,
      siteName: this.siteName
    };

    fetch(`/umbraco/api/search/site`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.buildResult(json);
        if (json.total > this.pagePerView) {
          this.generatePager(json.total);
        } else {
          this.pagingContainer.innerHTML = "";
        }
      })
      .catch(e => {});
  }

  buildResult(result) {
    const htmlTemplate = result.results
      .map(item => {
        let date = new Date(item.date);
        let formattetDate = date.toLocaleDateString("da-DK");
        return `
        <div class="col-xs-10 col-xs-offset-1 col-md-9 col-md-offset-1 col-lg-9 col-lg-offset-2">
                <a href="${item.url}" aria-label="link til lalala" class="result-box color-petroleum">
                    <div class="arrow-icon-container">
                        <div class="arrow-icon">
                            <svg class="svg-arrow-right">
                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-arrow-right"></use>
                            </svg>
                        </div>
                    </div>
                    <div class="result-content">
                        <p class="result-title h2">${item.title}</p>
                        <p class="result-description">${item.description}</p>
                        <p class="result-more color-dark-green">Læs mere</p>
                    </div>
                </a>
            </div>
      `;
      })
      .join("");

    this.resultList.innerHTML = "";
    this.resultList.innerHTML = htmlTemplate;

    const params = new URLSearchParams(location.search);
    var showing =
      this.pagePerView < result.total ? this.pagePerView : result.total;

    var resultNode = document.createElement("p");
    if (params.get("query") != null) {
      var queryNode = document.createElement("span");
      queryNode.textContent = params.get("query");
      queryNode.classList.add("query");
      resultNode.appendChild(document.createTextNode("Din søgning på \""));
      resultNode.appendChild(queryNode);
      resultNode.appendChild(document.createTextNode("\" gav " + result.total + " resultater."));
    } else {
      resultNode.innerHTML = "Viser " +
        showing +
        " ud af <span>" +
        result.total +
        "</span> resultater";
    }
    this.resultText.innerHTML = "";
    this.resultText.appendChild(resultNode);
  }

  generatePager(total) {
    const params = new URLSearchParams(location.search);
    let pagingParameter = params.get("page");
    let pages = Math.floor(total / this.pagePerView);
    let extra = total % this.pagePerView == 0 ? 0 : 1;
    let totalPages = pages + extra;

    var pagerTemplate = "";

    for (let step = 1; step <= totalPages; step++) {
      var isActive =
        pagingParameter == step || (step == 1 && pagingParameter == null)
          ? "active"
          : "";

      pagerTemplate =
        pagerTemplate +
        '<a class="paging-item ' +
        isActive +
        '" href="#" aria-label="Side ' +
        step +
        '" data-page="' +
        step +
        '">' +
        step +
        "</a>";
    }
    var startStep =
      '<a class="paging-item paging-back" data-page="0" href="#" aria-label="Forrige ' +
      this.pagePerView +
      ' sider"><svg class="svg-small-arrow-left"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-small-arrow-left"></use></svg></a>';
    var endStep =
      '<a class="paging-item paging-forward" data-page="999" href="#" aria-label="Næste ' +
      this.pagePerView +
      ' sider"><svg class="svg-small-arrow-right"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-small-arrow-right"></use></svg></a>';

    this.pagingContainer.innerHTML = startStep + pagerTemplate + endStep;
    this.pageButtons = this.el.querySelectorAll(".paging-item");
    this.pageButtons.forEach(element => {
      element.addEventListener("click", event => {
        event.preventDefault();

        this.usePager(element, totalPages);
      });
    });
  }

  usePager(button, totalPages) {
    const params = new URLSearchParams(location.search);
    var activePage = button.dataset.page;
    var pressentActiveButton = this.el.querySelector(".paging-item.active");
    var presentActivePage = pressentActiveButton.dataset.page;

    if (activePage != 0 && activePage != 999) {
      this.pageButtons.forEach(element => {
        var page = element.dataset.page;
        if (activePage == page) {
          element.classList.add("active");
          params.set("page", page);
          window.history.pushState({}, "", "?" + params.toString());

          this.generateResult();
        } else {
          element.classList.remove("active");
        }
      });
    } else if (activePage == 0 && presentActivePage != 1) {
      pressentActiveButton.classList.remove("active");
      var newActivePage = pressentActiveButton.previousSibling.classList.add(
        "active"
      );
      var newParam = Math.trunc(presentActivePage) - 1;
      params.set("page", newParam);
      window.history.pushState({}, "", "?" + params.toString());

      this.generateResult();
    } else if (activePage == 999 && presentActivePage != totalPages) {
      pressentActiveButton.classList.remove("active");
      pressentActiveButton.nextSibling.classList.add("active");
      var newParam = Math.trunc(presentActivePage) + 1;
      params.set("page", newParam);
      window.history.pushState({}, "", "?" + params.toString());

      this.generateResult();
    } else {
      return;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

  searchSite(query) {
    const params = new URLSearchParams(location.search);
    if (params.get("page") != null) {
      params.delete("page");
    }
    if (query.length > 0) {
      params.set("query", query);
    } else {
      params.delete("query");
    }
    window.history.pushState({}, "", "?" + params.toString());
    this.generateResult();
  }
}
